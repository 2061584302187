import { Button, TextField } from '@mui/material';
import LoginStyled from './Login.styled';
import React, { useState } from 'react';
import { POST } from '../../utils';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { toast } from 'react-toastify';

const Login = () => {
	const [username, set_username] = useState('');
	const [password, setPassword] = useState('');
	const { token, set_token, set_user } = useAuth();
	const [loading, set_loading] = useState(false);
	const { state } = useLocation();

	const login = async (e) => {
		e.preventDefault();
		set_loading(true);
		const res = await POST({
			endpoint: '/authentication',
			auth: false,
			data: { username, password }
		});
		set_loading(false);
		if (res?.message === 'success') {
			toast.success('تم تسجيل الدخول بنجاح', {
				autoClose: 2000
			});
			set_token(res?.data?.accessToken);
			set_user(res?.data?.providerUser);
		} else {
			toast.error(res?.data?.detail);
		}
	};

	if (token) {
		return <Navigate to={state?.path || '/'} />;
	}

	return (
		<LoginStyled>
			<div className="login-card">
				<img src="/img/logo.svg" alt="Qawafel's logo" className="logo" />
				<h2>تسجيل الدخول لادارة شحنات قوافل</h2>
				<form onSubmit={(e) => login(e)}>
					<TextField
						id="outlined-basic"
						label="username"
						variant="outlined"
						value={username}
						onChange={(event) => {
							set_username(event.target.value);
						}}
					/>
					<TextField
						id="filled-basic"
						type="password"
						label="Password"
						variant="outlined"
						value={password}
						onChange={(event) => {
							setPassword(event.target.value);
						}}
					/>
					<p className="terms">
						عند استخدامك منصة قوافل فأنت توافق على
						<br />
						<a
							className="inline-block"
							href="https://qawafel.sa/terms-conditions"
							target="_blank"
							rel="noreferrer"
						>
							<small>الشروط والأحكام</small>
						</a>{' '}
						<a
							className="inline-block"
							href="https://qawafel.sa/privacy-policy"
							target="_blank"
							rel="noreferrer"
						>
							<small>وسياسة الخصوصية</small>
						</a>
					</p>
					<Button
						variant="contained"
						disabled={loading || !password || !username}
						size="large"
						type="submit"
					>
						Login
					</Button>
				</form>
			</div>
		</LoginStyled>
	);
};

export default Login;
